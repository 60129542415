import { showSlider } from './slider';

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM fully loaded and parsed');
  window.addEventListener('scroll', function() {
    var primary = document.getElementById('static');
    var sticky = document.getElementById('sticky');
    var distanceToTop = primary.getBoundingClientRect().bottom;
    
    if(distanceToTop < -200) {
      sticky.style.cssText = 'height: 50px; overflow: initial;';
    } else {
      sticky.style.cssText = 'height: 0; overflow: hidden;';
    }
 });

  let open_menu = document.getElementById('burguer');
  let open_menu_sticky = document.getElementById('burguer-sticky');
  let close_menu = document.getElementById('close');
  let menu_mobile = document.getElementById('menu-mobile');
  open_menu.addEventListener('click', function () {
    menu_mobile.style.cssText = 'width: 100%';
  });
  open_menu_sticky.addEventListener('click', function () {
    console.log('entro')
    menu_mobile.style.cssText = 'width: 100%';
  });
  close_menu.addEventListener('click', function () {
    menu_mobile.style.cssText = 'width: 0';
  });


});

showSlider();